import './App.css';
import './styles.css';
import Image from './image.png'
function App() {
  return (
    <div className="page">
      <div className='div-flex-column'>
        <div>
          <h1>BuildMentorInfra</h1>
          <h4>Contact Us- contact@buildmentorinfra.com </h4>
        </div>
        <div>
          <h4>Site Under Construction</h4>
          <img src={Image} alt='' className='image'/>
        </div>
      </div>
    </div>
  );
}

export default App;
